import _msp_constants from './constants';
import _msp_api from './api';
import _msp_color from './colors.tinycolor.js';
import _msp_interfaces from './interfaces.js';
import _msp_utente from './utente.js';
import moment from 'moment';
import _calendario from '../vue/mixins/calendario.js'
import { alertSoloAllenatore } from './alert.js'
const MSP = {};
MSP.utente = _msp_utente;
MSP.alertSoloAllenatore = alertSoloAllenatore;
MSP.number = {}
MSP.date = _calendario;
MSP.number.pad = function (number, size)
{
    let s = String(number);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

/** Funzione usata quando si vuole intercettare un errore da Promise (catch)*/
MSP.catch = function (error)
{
  // Non fare nulla... SHHHH....
  throw 'Uh-oh! ' + error;


}

/** Converte un array o una proprietà di un oggetto in parametri
* @param {array} arr array o object di cui serve la key
* @param {string} paramName nome del parametro
* @param {string} objectKey nome della proprietà
* @return {string} stringa parametri
*/
MSP.array2params = function (arr, paramName, objectKey = null)
{
  let params;
  if (objectKey) {
    params = arr[objectKey].map(function(el, idx) {
      return paramName+'['+objectKey+'][' + idx + ']=' + el;
    }).join('&');
  } else {
    params = arr.map(function(el, idx) {
      return paramName+'[' + idx + ']=' + el;
    }).join('&');
  }
    return params;
}

  MSP.isValidJSON = function (text) {
    if (!text)
    {
    return false;
  }
  return (/^[\],:{}\s]*$/.test((""+text).replace(/\\["\\/bfnrtu]/g, '@').
replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').
replace(/(?:^|:|,)(?:\s*\[)+/g, '')))
}

MSP.isValidJSON2 = function (text) {
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
}
MSP.getKeyByValue = function (object, value) {
  return Object.keys(object).find(key => object[key] === value);
}


MSP.timeout = null;


MSP.debouncePromise = function (fn, wait = 0, options = {}) {
  let lastCallAt
  let deferred
  let pendingArgs = []
  return function debounced (...args) {
    const currentWait = getWait(wait)
    const currentTime = new Date().getTime()

    const isCold = !lastCallAt || (currentTime - lastCallAt) > currentWait

    lastCallAt = currentTime

    if (isCold && options.leading) {
      return options.accumulate
        ? Promise.resolve(fn.call(this, [args])).then(result => result[0])
        : Promise.resolve(fn.call(this, ...args))
    }

    if (deferred) {
      clearTimeout(MSP.timeout)
    } else {
      deferred = defer()
    }

    pendingArgs.push(args)
    MSP.timeout = setTimeout(flush.bind(this), currentWait)

    if (options.accumulate) {
      const argsIndex = pendingArgs.length - 1
      return deferred.promise.then(results => results[argsIndex])
    }

    return deferred.promise
  }

  function flush () {

    const thisDeferred = deferred
    clearTimeout(MSP.timeout)
    MSP.timeout = null;

    Promise.resolve(
      options.accumulate
        ? fn.call(this, pendingArgs)
        : fn.apply(this, pendingArgs[pendingArgs.length - 1])
    )
      .then(thisDeferred.resolve, thisDeferred.reject)

    pendingArgs = []
    deferred = null
  }
}

function getWait (wait) {
  return (typeof wait === 'function') ? wait() : wait
}

function defer () {
  const deferred = {}
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  return deferred
}



MSP.debounce = function (func, wait, immediate = false) {
  return function() {
    let context = this;
    let args = arguments;
    let later = function() {
      MSP.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !MSP.timeout;
    clearTimeout(MSP.timeout);
    MSP.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/** funzione sort che sulla base degli elementi modificati secondo la funzione passata come paramtro filter
* @param {any} a qualsiasi elemento da usare nel confronto
* @param {any} b qualsiasi elemento da usare nel confronto
* @param {function} filter funzione che trasforma gli elementi passati come argomenti a, b su cui fare il confronto
* @return {number} numero usato in funzioni di sort (-1: minore, 0: uguale, 1: maggiore)
*/
MSP.sortByFilter = function (a, b, filter) {
  var nameA = filter(a);
  var nameB = filter(b);
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

/** Filtra un oggetto in base alle proprietà fornite in forma di stringhe separate da virgola (csv). Se nessuna proprietà fornita è presente nell'oggetto originale, restitusce un oggetto vuoto.
* @param {object} obj Oggetto da filtrare.
* @param {string} csv_props Elenco di proprietà valide, separate da virgola.
* @return {object} Oggetto con le proprietà elencate in csv_props. Se nessuna proprietà fornita è presente nell'oggetto originale, restitusce un oggetto vuoto.
*/
MSP.filterByProps = function (obj, csv_props/*string*/)
{
  let filtered = {};
  let a_props = csv_props.split(",");
  for(let key in obj) {
    if (a_props.indexOf(key) > -1) {
      filtered[key] = obj[key];
    }
  }
  return filtered;
};

MSP.deepProp = function (obj, commaString) {
    const parts = commaString.split('.');
    let target = {...obj};
    while (parts.length > 1) {
        const part = parts.shift();
        target = target[part] || {};
    }
    let prop = target[parts[0]];
    return prop;
}

/** Trova gli elementi di un array che contengano tutte le parole
* @param {Array} a_items Array di oggetti in cui cercare
* @param {String} s_values Lista di parole separate da spazi
* @param {Array} properties Proprietà dell'oggetto in cui cercare
* @return {Array} Array filtrata
*/
MSP.filter = function (a_items, s_values, properties) {
    if (!a_items) return [];
    let filtered = [...a_items];

    if (!(s_values && s_values !== "")) {
        return filtered;
    } else {
        let a_values = s_values.split(" ");

        a_values.forEach((searchValue)=>{
            searchValue = searchValue.toLowerCase();
            let regExp = new RegExp(searchValue,'g');

            let filter = function (el){
                let a_combProps = [];
                properties.forEach(
                    (property)=>{
                        let string = MSP.deepProp(el, property);
                        if (!(string && string.length)) return;
                        a_combProps.push(string.toLowerCase());
                    }
                );
                let combProps = a_combProps.join(" ");
                return combProps.match(regExp);
            }
            filtered = filtered.filter(filter);

        });
    }
    return filtered;

}

MSP.isEmpty = function (obj) {
  if(Object.prototype.hasOwnProperty.call(obj,"length")) {
    return obj.length === 0;
  }

  for(let key in obj) {
    if(Object.prototype.hasOwnProperty.call(obj,key))
    {
      return false;
    }
  }
  return true;
};

MSP._extends = Object.assign || function (target) { for (let i = 1; i < arguments.length; i++) { let source = arguments[i]; for (let key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

MSP.setProp = function (obj, prop, value)
{
  let newProp = {};
  newProp[prop] = value;
  return Object.assign({}, obj, newProp);
}


MSP.getKey = function (obj, val) {
  for (let key in obj) {
    if (obj[key] === val)
    {
      return key;
    }
  }
  return null;
}

MSP.getPropById = function (arr, id_key, id_value, prop)
{
  id_key = id_key || 'id';
  let obj = arr.find(el=>el[id_key]==id_value);
  if (obj)
  {
    return obj[prop]
  }
  return false;
},

MSP.Arr = function(data) {
  data = data || [];
  var obj = {
    set: function (newData) {
      if(Object.prototype.hasOwnProperty.call(newData,'length'))
      {
        data = newData;
      }
    },
    all: function () {
      return data;
    },
    getBy: function (key, val) {
      var fi = data.find(function(el) {
        return el[key] === val;
      });

      return fi;

    }
  };
  return obj;
}




/**Restitusce un array dei mesi nel range di date fornito, con informazioni del nome, del primo giorno e dell'ultimo giorno
* @param {Date} startDate primo giorno del range
* @param {Date} endDate ultimo giorno del range
* @return {Array} lista dei mesi, nel formato {str, first, last}
*/
MSP.getMonths = function (startDate, endDate)
{
  var resultList = [];
  var monthNameList = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

  while (startDate <= endDate)
  {
    var stringDate = monthNameList[startDate.getMonth()] + " " + startDate.getFullYear();

    //get first and last day of month
    var firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    var lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    resultList.push({
      str:stringDate,
      first:firstDay,
      last:lastDay,
    });
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return resultList;
};


MSP.getMonday = _calendario.getMonday;

MSP.countDays = function (from, to)
{
  from = from ? new Date(from) : new Date();
  to = to ? new Date(to) : new Date();
  return Math.ceil((to-from)/86400000);
}


/** converte una data usando il separatore come elemento di riconoscimento.
* Se la stringa passata contiene "-", converte da YYYY-MM-DD in DD/MM/YYYY
* Se la stringa passata contiene "/", converte da DD/MM/YYYY in YYYY-MM-DD
* @param {String} date data
* @param {String} to @values ["-","/"] forza l'output nel formato scelto
* @return {String} data convertita
*/
MSP.convertDate = function (date,to) {
  if (!date) { return false; }
  if (date.indexOf(to) !== -1) { return date; }
    let hour;
    let a_date = date.split(" ");
        date = a_date[0];
    if (a_date.length > 1) {
        hour = a_date[1];
    }

  let a = ["-", "/"];
  if (date.indexOf('-') === -1) {
    a.reverse();
  }
  date = date.split(a[0]).reverse().join(a[1]);
    if (!hour) {
        return date;
    } else {
        return date+" "+hour;
    }
}

/** crea una copia di un oggetto
* @param {Object} obj oggetto da clonare
* @return {Object} clone
*/
MSP.clone = function(obj) {
  if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
  return obj;
  let temp;
  if (obj instanceof Date)
  {
    temp = new obj.constructor(); //or new Date(obj);
  }
  else
  {
    temp = obj.constructor();
  }

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj['isActiveClone'] = null;
      temp[key] = MSP.clone(obj[key]);
      delete obj['isActiveClone'];
    }
  }
  return temp;
}


/** Aggoimge un valore alla proprietà di un oggetto.
* Se la proprietà non esiste, la imposta = 0
* @param {Object} obj l'oggetto di riferimnento
* @param {String} key la proprietà dell'oggetto
* @param {Number} val la quantità da aggiungere alla proprietà dell'oggetto
* @return {Object} l'oggetto originale con la proprietà modificata
*/
MSP.sumObjKey = function (obj, key, val) {
  if (!Object.prototype.hasOwnProperty.call(obj,key))
  {
    obj[key] = 0;
  }
  obj[key] += val;
  return obj;
}
MSP.fetchArrKey = function (arr, what, options) {
  if (!arr) {
    return false;
  }
  let id = options.id || 'id';
  let result = options.result || 'desc' ;
  let find = arr.filter((el)=>{
    let has_id = Object.prototype.hasOwnProperty.call(el,id);
    let has_result = Object.prototype.hasOwnProperty.call(el,result);

    if (!(has_id&&has_result)) {
      return false;
    }
    if (typeof options.normalize === 'function') {
      return (options.normalize(el[id]) === options.normalize(what));

    } else {
      return ((el[id]) === (what));
    }

  });

  if (find.length === 0) {
    return false;
  }
  if (find.length > 1) {
    return find[0][result];
  }
  return find[0][result];
}


// ### MATH ###

MSP.normalized = function (val, min_in, max_in, min_out, max_out)
{
  return min_out + (val - min_in)/(max_in - min_in)*(max_out-min_out);

}

MSP.int = function(o) {
  let i = parseInt(o, 10);
  if (isNaN(i)) {
    return 0;
  }
  return i;
}

MSP.toInteger = function (val)
{
  let number = val.replace(/[\D|.]/g, '');
  if(isNaN(number))
  {
    return 0;
  }
  else
  {
    return parseInt(number, 10);
  }
};

MSP.toNumber = function (val)
{
  val = ""+val;
  val = val.replace(/[,]/g, '.');
  val = val.replace(/[^\d.]/g, '');
  //elimina doppi punti
  val = val.replace(/\..*/, c => "." + c.replace(/\./g, () => ""))
  return val;
}

MSP.decimal = function (val, digits = 0)
{
  var multiplier = Math.pow(10, digits || 0);
  return Math.round(MSP.toNumber(val) * multiplier) / multiplier;
};

// ### STRING ###


/** restituisce una stringa come concatenazione di proprietà e stringhe
* @param {object} item oggetto con proprietà da concatenare
* @param {string} pattern string una stringa con elementi separati da virgole: possono essere proprietà di item o stringhe
* @return {string}
*/
MSP.concat = function (item, pattern) {
  var item_label = '';
  let regExp = new RegExp("\"|'",'g');

  let labels = pattern.split(",");
  labels.map(label=>{
    label = label.trim();
    if (label.match(regExp)) {
      item_label += label.replace(regExp, '');
    }
    else
    {
      item_label += item[label];
    }
  });
  return item_label;
}


// ### MISC ###


MSP.unique = function(id) {
  let newID = +new Date();
  if (typeof id === 'undefined') {
    return newID +1 ;
  }
  const params = id.split("_");
  if (params.length === 1) {
    return MSP.int(id);
  }
  if (params[0] === "not") {
    if (newID === params[1]) {
      return newID + 1;
    }
  }
  return newID;
}

      MSP.textAreaAdjust = function MSPTextAreaAdjust(o,count=0,max=10) {
          count++;
          let br = o.getBoundingClientRect();
          // se l'altezza è nulla, ricalcola la dimensione
          // Limita i tentativi per evitare loop infiniti
          if (!br.height && count<max) {
              setTimeout(()=>{
                  MSPTextAreaAdjust(o, count);
              }, 300);
          } else {
              let om = o.style.marginBottom;
              o.style.marginBottom = br.height+"px";
              o.style.height = "1px";
              o.style.height = (25+o.scrollHeight)+"px";
              o.style.marginBottom = om;
          }
      }





  MSP.setBackground = function (col, a) {
    if (!col) {
      return "";
    }
    var bgc = _msp_color.mix("#ffffff", col, a);

    var style = "backgroundColor: " + bgc + ";";
    return style;
  };
  MSP.setBorder = function (col) {
    if (!col) {
      return "";
    }
    var style = "borderBottomColor: " + col + ";";
    return style;
  };
  MSP.setTextWhite = function (col) {
    if (!col) {
      return "";
    }
    const tc = _msp_color.color(col);
    let textColor;
    if (!tc.isLight()) {
      textColor = "color: #fff;";
    } else {
      textColor = "";
    }
    return textColor;
  };


MSP.normalizeTime = function(zoneValue, conv)
{
  var val = 0;
  if (conv === 'm:s')
  {
    val = MSP.hms2sec(zoneValue);
  } else {
    val = parseInt(zoneValue, 10);
  }
  return val;
};

/** normalizza le zone, in base a diverse sorgenti
*/
MSP.normalizeZone = function(zoneObj, time, dist)
{
  let conv;
  if (dist === 'km')
  {
    conv = 1000;
  } else if (dist === '100mt')
  {
    conv = 100;
  } else {
    conv = parseInt(dist, 10);
  }
  var normalized = {};
  for(let key in zoneObj)
  {
    normalized[key] = MSP.normalizeTime(zoneObj[key], time) / conv;
  }
  return normalized;
},

MSP.getUnits = function (um_id) {
  um_id = MSP.int(um_id);
  let um = MSP.getKey(_msp_constants.UMS, um_id);
  return um && _msp_constants.CONVERSIONI[um] || 1;
}

/** verifica se l'unità di misura è in minuti)
* @param {String|Number} um_id id dell'unità di misura (1:km, 2:mt)
* @return {Boolean}
*/
MSP.isMinutes = function (um_id) {
  return MSP.int(um_id) === _msp_constants.UMS.min;
};


/** verifica se l'unità di misura è in H:M:S)
* @param {String|Number} um_id id dell'unità di misura (1:km, 2:mt, 3:hms)
* @return {Boolean}
*/
MSP.isHms = function (um_id) {
  um_id = MSP.int(um_id);
  return (um_id === _msp_constants.UMS.hms) || (um_id === _msp_constants.UMS.ms);
};

/** verifica se l'unità di misura è in H:M:S)
* @param {String|Number} um_id id dell'unità di misura (1:km, 2:mt, 3:hms)
* @return {Boolean}
*/
MSP.isUmTipoHms = function (um_tipo_id) {
  const values = [1,7];
  return (values.indexOf(um_tipo_id) !== -1);
};

/** verifica se il formato del valore è H:M:S o M:S
* @param {String|Number} value valore
* @return {Boolean}
*/
MSP.isFormatHms = function (value) {
  return (value.indexOf(':') !== -1);
};

/** verifica se l'unità di misura è una distanza (metri o chilometri)
* @param {String|Number} um_id id dell'unità di misura (1:km, 2:mt)
* @return {Number|false}
*/
MSP.isDistance = function (um_id) {
  if (
    MSP.isMeters(um_id) || MSP.isKm(um_id)
  ) {
    return um_id;
  }
  return false;
}
MSP.isTime = function (um_id) {
  const values = [
    _msp_constants.UMS['hh:mm:ss'],
    _msp_constants.UMS['min'],
    _msp_constants.UMS['sec'],
    _msp_constants.UMS['min/km'],
    _msp_constants.UMS['min/100mt'],
    _msp_constants.UMS['mm:ss'],
    _msp_constants.UMS['sec/100mt'],
    _msp_constants.UMS['sec/km'],
  ]
  return values.indexOf(um_id) !== -1;
}

MSP.isMeters = function (um_id) {
  um_id = MSP.int(um_id);
  return  (um_id === _msp_constants.UMS.mt);
}
MSP.isKm = function (um_id) {
  um_id = MSP.int(um_id);
  return  (um_id === _msp_constants.UMS.km);
}


MSP.UMPasso = function (sport_id) {
  sport_id = MSP.int(sport_id);
  var um = "";
  if (sport_id === _msp_constants.sport_id.nuoto) {
    um = 'min/100mt';
  } else if (sport_id === _msp_constants.sport_id.podismo) {
    um = 'min/km';
  }

  return um;
}

MSP.formatDateYmd = function (date) {
    return _calendario.date2ymd(date);
}
MSP.formatDate = function (timestamp, format) {
  format = format || "DD/MM/YYYY";
    if (!timestamp) { return ""; }
  return moment(timestamp).format(format);
}
MSP.formatRisultato = function (val, um) {
  let value;
  if (!MSP.int(val)) {
    return "/";
  }

  if (um === "mm:ss") {
    value = MSP.sec2hms(val, true, false);
  } else if (um === 'min/100mt') {
    value = MSP.sec2hms(val, true, false);
  } else if (um === 'min') {
    value = MSP.sec2hms(val);
  } else if (um === 'hms') {
    value = MSP.sec2hms(val);
  } else if (um === 'km') {
    value = MSP.decimal(parseInt(val, 10)/1000, 1);
  } else if (um === 'min/km') {
    value = MSP.sec2hms(val, true, false);
  } else if (um == 1){
    value = MSP.decimal(val, 1);
  } else {
    value = MSP.decimal(val, 0);
  }
  return value;// + " " + um;
};


/** Converte i valori del passo nell'um
* Poiché tutti i valori sono in sec/mt, serve solo il fattore di conversione in base alla descrizione dell'UM
* Se non è presente l'um nell'elenco delel conversioni, allora usa il valore
*/
MSP.convertValueFromMt = function (val, um_descrizione) {
  let new_val;
  let conversioni = {
    'min/100mt': 100,
    'min/km': 1000,
    // 'km': 1/1000
  }

  let fact;
  if (conversioni[um_descrizione] !== undefined) {
    fact = conversioni[um_descrizione];
  } else {
    fact = 1;
  }

  new_val = val * fact;

  return new_val;

}



MSP.formatRisultatoByUmId = function (val, um_id, um_descrizione = 'min/km', hasUm = true)
{
  let label_um = um_descrizione || false;
  let converted = MSP.convertValueFromMt(val, um_descrizione);
  if (!(label_um && converted))
  {
    return "/";
  }

  let um = (hasUm) ? " " + um_descrizione : "";
  if (MSP.isTime(um_id)) {
    return MSP.formatRisultato(converted, 'mm:ss') + um;
  } else {
    return MSP.formatRisultato(converted, um_id) + um;

  }

},

MSP.getDistTime = function (val, factor, isDist) {
  var distance;
  var time;

  if (!factor) {
    if (isDist) {
      distance = val;
      time = 0;
    } else {
      time = val;
      distance = 0;
    }
  } else {
    if (isDist) {
      distance = val;
      time = val * factor;
    } else {
      time = val;
      distance = val / factor;
    }
  }

  return {
    distance: distance,
    time: time
  }
}

MSP.sec2Um = function (val, um) {
  um = parseInt(um, 10);
  if (um === 3) {
    return MSP.sec2hms(val);
  } else if (um === 7) {
    return val / 60;
  } else {
    return val;
  }
}



/** divide la stringa in base ai caratteri diversi dai numeri: (es validi: hh:mm:ss | mm.ss | ss | hh-mm-ss ).
* Se non ci sono separatori, considera il valore come secondi
* @param {String} time in formato hh:mm:ss
* @return {Number} secondi
*/
MSP.hms2sec = function(time){
  let i = 0;
  let sep = time.match(/\D/);
  if (!sep) {
    return time;
  }
  let HHMMSS=time.split(sep[0]).reverse();
  let secs = HHMMSS.reduce(function (a, b) {
    i ++;
    let res = Number(a) + Number(b) * Math.pow(60, i);
    return res;
  });
  return secs;
}

/** converte secondi in stringa formato HH:MM:SS
* @param {Number} d secondi
* @param {Boolean} isMS solo minuti:secondi
* @param {Boolean} hasDigits aggiunge i decimali
* @return {String} HH:MM:SS
*/
MSP.sec2hms = function (d, isMS = false, hasDigits = false) {
  d = +d;

  var h = Math.floor(d / 3600) || 0;
  var m = Math.floor(d % 3600 / 60) || 0;
  var s = Math.floor(d % 3600 % 60) || 0;

  /** Valore dei secondi convertito in hh:mm:ss[,digits] */
  let result =  ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);

  if (h > 0 || !isMS) {
    result = h + ":" + result;
  }

  if (hasDigits) {
    let digits = Math.floor((d % 1) * 10);
    result +=","+digits;
  }
  return result;
};

/** converte secondi in stringa formato HH:MM
* @param {Number} d secondi
* @param {Boolean} isMS solo minuti:secondi
* @param {Boolean} hasDigits aggiunge i decimali
* @return {String} HH:MM:SS
*/
MSP.sec2hm = function (d) {
  d = +d;

  var h = Math.floor(d / 3600) || 0;
  var m = Math.floor(d % 3600 / 60) || 0;

  let result =  ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2);

  return result;
};



MSP.um2um = function (val, id_um1, id_um2) {
  return val * MSP.getUnits(id_um1) / MSP.getUnits(id_um2) || 0;
}

MSP.mt2km = function (val) {
  return MSP.um2um(val, _msp_constants.UMS.mt, _msp_constants.UMS.km);
}

MSP.km2mt = function (val) {
  return MSP.um2um(val, _msp_constants.UMS.km, _msp_constants.UMS.mt);
}

MSP.min2sec = function (val) {
  return MSP.um2um(val, _msp_constants.UMS.min, _msp_constants.UMS.sec);

};


MSP.formatCalorie = function (cal) {
  return (cal > 100) ? Math.round(cal) + " kCal" : "--";
};
MSP.formatBpm = function (bpm) {
  return (bpm > 0) ? Math.round(bpm) + " bpm" : "--";
};
MSP.formatPotenza = function (watt) {
  return (watt > 0) ? Math.round(watt) + " W" : "--";
};
MSP.formatElevazione = function (elev) {
  return (elev > 0) ? Math.round(elev) + " mt" : "--";
};
MSP.formatCadenza = function (cad) {
  return (cad > 0) ? Math.round(cad) + " rpm" : "--";
};
MSP.calcoloPassoNuotoSec = function (sec) {
  return MSP.sec2hms(sec, true) + " min/100mt";
};
MSP.calcoloPassoNuotoMin = function (min) {
  return MSP.sec2hms(min*60, true) + " min/100mt";
};
MSP.calcoloPassoRunningSec = function (sec) {
  return MSP.sec2hms(sec, true) + " min/km";
};
MSP.calcoloPassoRunningMin = function (min) {
  return MSP.sec2hms(min * 60, true) + " min/km";
};

MSP.calcoloVelocita = function (vel) {
  return Math.round(vel * 3.6) * 100 / 100 + " kmh";
};


MSP.getPassoKm = function (tempo, distanza) {
    return Math.round((tempo * 1000) / distanza);
}

MSP.kmh2passoKm = function(v) {
  if (!(+v && v!==Infinity && !isNaN(v))) {
    return "/";
  }
  let min = Math.floor(60/v);
  let sec = Math.round(((60/v) - Math.floor(60/v)) * 60);
  return min + ":" + sec;
}

MSP.kmh2passo100mt = function(v) {
  if (!(+v && v!==Infinity && !isNaN(v)))
  {
    return "/";
  }
  v = v * 10;
  let min = Math.floor(60/v);
  let sec = Math.round(((60/v) - Math.floor(60/v)) * 60);
  let result =  min + ":" + sec;
  let digits = Math.floor((v % 1) * 10);
  result +=","+digits;
  return result;

}


MSP.formatMtBySport = function (meters, sport_id) {
  if (
    (MSP.int(sport_id) === _msp_constants.sport_id.podismo)
  )
  {
    return MSP.decimal(MSP.um2um(meters, _msp_constants.UMS.mt, _msp_constants.UMS.km ), 1) + " km" ;
  } else if (
    (MSP.int(sport_id) === _msp_constants.sport_id.ciclismo)
  )
  {
    return "";
  } else {
    return MSP.decimal(meters,0) + " mt" ;
  }
};

MSP.umLabel = function (um)
{
  const ums = ['hms','ms'];
  if (ums.indexOf(um) === -1)
  {
    return um;
  }
}

// ### sport
/** Risolve la differenza tra zone nuoto e zone Z.
* Per il nuoto, usa le zone della distanza 100 mt.
* @param {Object} init_zone
* @param {Number} sport_id
* @return {Object} {zona_codice: valore}
*/
MSP.makeZoneBySport = function (init_zone, sport_id)
{
  return (init_zone&&(sport_id == _msp_constants.sport_id.nuoto)) ? (init_zone.distanze['100'].zone) : init_zone;
}



MSP.isCiclismo = function (sport_id) {
  return +sport_id === +_msp_constants.ID_CICLISMO;
}
MSP.isPodismo = function (sport_id) {
  return +sport_id === +_msp_constants.ID_PODISMO;
}
MSP.isNuoto = function (sport_id) {
  return +sport_id === +_msp_constants.ID_NUOTO;
}
MSP.isPalestra = function (sport_id) {
  return +sport_id === +_msp_constants.ID_PALESTRA;
}
MSP.isSci = function (sport_id) {
  return +sport_id === +_msp_constants.ID_SCI;
}


MSP.findSport = function (id) {
    const sport = _msp_constants.SPORT.find(el=>el.id == id);
    return sport;
}

MSP.hasCalcoli = function (sport_id) {
   const sport = MSP.findSport(sport_id);
    if (!sport) return false;
    return +sport.has_calcoli;
},


MSP.hasAndature = function (sport_id) {
   const sport = MSP.findSport(sport_id);
    if (!sport) return false;
    return +sport.has_andature;
},

MSP.hasZone = function (sport_id) {
   const sport = MSP.findSport(sport_id);
    if (!sport) return false;
    return +sport.has_zone;
},

MSP.hasDistance = function (sport_id) {
   const sport = MSP.findSport(sport_id);
    if (!sport) return false;
    return +sport.has_distance;
},

MSP.hasGraficoAndature = function ({zone_attive, dettagli}) {
    if (!(zone_attive && zone_attive.length)) return false;
    if (!(dettagli && dettagli.length)) return false;
    return true;
}

/** Dato il valore dell'andatura e la sua unità di misura, ricava i metri e i secondi sulla base delle zone di riferimento.
* Essendo tutte le distanze in metri e tutti i tempi in secondi, non convertire.
* @param {Object} andatura
* @param {Array} zone
* @param {Number} ripetute
* @return {Object} {mt: Number, sec: Number}
*
*/
MSP.convertiMtSecAndatura = function (andatura, zone) {
    let media;
    if (zone && zone.length) {
        let zona = zone.find(el=>(
            (+el.id==+andatura.andatura_zona_id)
            && (el.distanza=="100")
        ));
        if (zona) {
            let norm = zona.a - zona.da;
            let  da = zona.da + norm * andatura.andatura_zona_range_da/100;
            let  a = zona.da + norm * andatura.andatura_zona_range_a/100;
            media = (da + a) / 2;


        } else {
            media = 0;
        }
    } else {
        media = 0;
    }

    let totale = Object.assign({}, _msp_interfaces.totale());
    let conv_um = (MSP.isDistance(andatura.um_id)) ? _msp_constants.UMS.mt : _msp_constants.UMS.sec;
    let val = MSP.um2um(andatura.val_andatura, andatura.um_id, conv_um);
    let valori = MSP.getDistTime(val, media, MSP.isDistance(andatura.um_id));
    totale.sec = +valori.time;
    totale.mt = +valori.distance;
    return totale;
};

/** Moltiplica i valori delle andature per le ripetute e genera il totale
    * diviso per zone.
    * Vedi il file allenamento_calcoli per altri dettagli e formula.
    * ./public/include/api_parts/allenamento_calcoli.php:374
    **/
MSP.sommaTotale = function (ripetute, andature, zone, trimps = null) {
    let emptyTotale = {
        mt: 0,
        sec: 0,
    }

    if (trimps && trimps.length) {
        emptyTotale.trimp = 0;
    }

    var totale = Object.assign({},_msp_interfaces.totale());
    totale.zone = Object.assign({});
    andature.forEach(
        (andatura) => {
            let totAndaturaMtSec = MSP.convertiMtSecAndatura(andatura, zone);
            let sec = totAndaturaMtSec.sec * ripetute;
            let mt = totAndaturaMtSec.mt * ripetute;
            totale.sec += sec;
            totale.mt += mt;
            if(totale.zone[""+andatura.andatura_zona_id] === undefined) {
                totale.zone[andatura.andatura_zona_id] = {...emptyTotale};
            }
            totale.zone[andatura.andatura_zona_id].sec += sec;
            totale.zone[andatura.andatura_zona_id].mt += mt;
            if (trimps && trimps.length) {
                let totAndaturaTrimp = MSP.calcolaTrimpAndatura(andatura, trimps);
                let trimp = totAndaturaTrimp * ripetute;
                totale.trimp += trimp;
                totale.zone[andatura.andatura_zona_id].trimp += trimp;
            }
        }
    );

    return totale;

};

MSP.fillEmpty = function (keys) {
    const filled =  keys.reduce((acc,el)=>{
        acc[el] = 0;
        return acc;
    }, {});
    return filled;
}
/** Somma i valori di tutte le proprietà del totale
*/
MSP.concatTotale = function ({accumulator, totale, isLavoro, keys}) {
    const defaultKeys = [
            "mt",
            "sec",
            "trimp",
        ];
    if (!keys) { keys = [...defaultKeys]; }

    const zoneKeys = [...defaultKeys];
    let result = Object.assign({},accumulator);
    if (!result.zone) {
        result.zone = {};
    }

    keys.forEach((key)=>{
        if (!result[key]) { result[key] = 0; }
        result[key] += totale[key];
    });

    const emptyTotale = MSP.fillEmpty(keys);
    const emptyZone = MSP.fillEmpty(zoneKeys);

    for (let zona_id in totale.zone) {
        if(result.zone[""+zona_id] === undefined) {
            result.zone[zona_id] = {...emptyZone};
        }
        zoneKeys.forEach((key)=>{
    //        result[key] += totaletrimp[key];

            if (totale.zone && totale.zone[zona_id] && totale.zone[zona_id][key]) {
                result.zone[zona_id][key] += totale.zone[zona_id][key];
            }
            if (isLavoro) {
                if(result.lavoro[""+zona_id] === undefined) {
                    result.lavoro[zona_id] = {...emptyTotale};
                }
                result.lavoro[zona_id][key] += totale.zone[zona_id][key];
            }
        });
    }
    return result;
}

/** Calcola il totale del dettaglio, usando i livelli
  required = {
    'dettaglio': {},
    'zone_attive': {},
    'trimps': [],
  }
*/
MSP.getTotaleDettaglio = function ({dettaglio,zone_attive,trimps}) {
    let totale = Object.assign({},_msp_interfaces.totale());
    let isLavoro = (dettaglio.des_attivita.toLowerCase().trim() === 'lavoro');
    dettaglio.serie && dettaglio.serie.map(
        (s) => {
            const totale_dettaglio = MSP.sommaTotale(s.ripetute, s.andature, zone_attive, trimps);
            if (totale_dettaglio) {
                const options = {
                    accumulator: totale,
                    totale: totale_dettaglio, 
                    isLavoro: isLavoro
                };
                totale = MSP.concatTotale(options);
            }
        }
    );
    return totale;
}


/**
    * Calcola il totale per ogni zona, quindi riassume il totalone.
    */
MSP.calcolaTotali = function (dettagli, zone_attive, trimps = null) {
    let a_totale = {
        'zone': [],
        'lavoro': [],
        'sec': 0,
        'mt': 0,
        'trimp': 0,
    };
    let emptyValues = {
        'sec': 0,
        'mt': 0,
        'trimp': 0,
    };
    if (!(dettagli)) {
        return a_totale;
    }
    dettagli.forEach((dettaglio)=>{
        if (!+dettaglio.ripetute) dettaglio.ripetute = 1;
        const ripetute = dettaglio.ripetute;
        // Deve convertire metri e secondi in base alle zone di riferimento,
        // quindi moltiplicare per le ripetute
        let totale = MSP.getTotaleDettaglio({
            dettaglio: dettaglio,
            zone_attive: zone_attive,
            trimps: trimps,
        });
        for(let k in totale.zone) {
            let values = {...emptyValues};
            if (!a_totale.zone[k]) {
                a_totale.zone[k] = {...emptyValues};
                for (let kv in values) {
                    values[kv] = totale.zone[k][kv] * ripetute;
                    a_totale.zone[k][kv] += values[kv];
                }
                // zone nella fase "lavoro"
                let isLavoro = (dettaglio.des_attivita.toLowerCase().trim() === 'lavoro');

                if (isLavoro) {
                    if (!a_totale.lavoro[k]) {
                        a_totale.lavoro[k] = {...emptyValues};
                    }
                    for (let kv in values) {
                        a_totale.lavoro[k][kv] += values[kv];
                    }
                }
            }
        }
    });
    for (let kv in emptyValues) {
        for (let k in a_totale.zone) {
            if (!a_totale[kv]) {
                a_totale[kv] = 0;
            }
            if (a_totale.zone[k][kv]) {
                a_totale[kv] += a_totale.zone[k][kv];
            }
        }
    }
    a_totale.lavoro = Object.assign({},a_totale.lavoro);
    a_totale.trimp = Math.round(a_totale.trimp);
    return a_totale;
}


/** TODO: Usare tutti i dati dell'andatura per il range da..a..*/
/** Normalizza il trimp nelle fasi di lavoro.*/
MSP.getDensita = function (trimps, lavoro_zone)
{
  let max_densita = 5.16;
  let min_in = 1.25;
  let max_in = max_densita;
  let min_out = 0;
  let max_out = 100;
  let lavoro = MSP.getTrimpLavoro(trimps, lavoro_zone);
  if (!lavoro.min)
  {
    return 1;
  }
  let densita = lavoro.trimp / lavoro.min;
  let normalized = MSP.normalized(densita, min_in, max_in, min_out, max_out);
  if (!normalized)
  {
    normalized = 0;
  }

  return MSP.decimal(normalized);

}



/** Calcola il totale dell'allenamento, usando i livelli
   required = {
    'allenamento': {},
    'zone_attive': {},
  }
*/
MSP.getTotaleAllenamento = function (params) {

  let totale = Object.assign({},_msp_interfaces.totale());
  if (!(params.allenamento && params.allenamento.dettagli))
  {
    return totale;
  }

  if (MSP.hasAndature(params.allenamento.sport_id))
  {
    let sport_id = params.allenamento.sport_id;
    params.allenamento.dettagli.map(
        (d) => {
            let totale_dettaglio = MSP.getTotaleDettaglio(
                {
                    'dettaglio': d,
                    'zone_attive': params.zone_attive,
                    'sport_id': sport_id,
                }
            );
            let isLavoro = (d.des_attivita.toLowerCase().trim() === 'lavoro');
            const options = {
                accumulator: totale,
                totale: totale_dettaglio, 
                isLavoro: isLavoro
            };
            totale = MSP.concatTotale(options);

        }
    );
  }
    totale.trimp = Math.round(totale.trimp);
  return totale;
};



//Librerie Database


MSP.loadZoneByIdSport = function (sport_id)
{
  return _msp_api.get('zona.php?sport_id='+sport_id,'/api/v2');
};


MSP.loadUms = function (sport_id) {
  let endpoint = 'um.php';
  if (sport_id) {
    endpoint += '?sport_id='+sport_id;
  }
  return _msp_api.get(endpoint, '/api/v2');
}


/* START: TRIMP, METS */
MSP.loadTrimp = function (sport_id) {
  return _msp_api.get('trimp.php?sport_id='+sport_id, '/api/v2');
};


/*CHECK OK*/
MSP.trimpSec = function (trimp, sec) {
    /** Il trimp è calcolato per minuti di attività. */
  return (sec/60) *  trimp;
};

MSP.metsSec = function (mets, sec) {
  return (sec/60/60) *  mets;
};


MSP.calcolaTrimpAndatura = function (andatura, trimps) {
    let trimp = trimps.find(el=>el.zona_id == andatura.andatura_zona_id);
    let media;
    if (trimp) {
        let norm = +trimp.trimp_a - +trimp.trimp_da;
        let  da = +trimp.trimp_da + norm * +andatura.andatura_zona_range_da/100;
        let  a = +trimp.trimp_da + norm * +andatura.andatura_zona_range_a/100;
        media = (da + a) / 2;
    } else {
        media = 0;
    }
    let result = media * andatura.val_andatura / 60;
    return MSP.decimal(result, 2);
}

/** TODO: Usare tutti i dati dell'andatura per il range da..a..*/
MSP.getTrimpLavoro = function (trimps, totaleLavoro) {
  //minuti di allenamento * trimp della zona
  let tot_empty = {
    trimp:0,
    min: 0
  };
  if(!(trimps && trimps.length)) {
    return tot_empty;
  }
  if (!(totaleLavoro)) {
    return tot_empty;
  }
  let tot = Object.assign({}, tot_empty);
  let trimp;
  let zone_sec = {};
  for (let k in totaleLavoro) {
    zone_sec[k] = totaleLavoro[k].sec;
  }
  tot.trimp += 0; //TODO: calcolare il trimp nelle fasi di lavoro
  for (let zona_id in zone_sec) {
    tot.min += zone_sec[zona_id] / 60;
  }
  trimp =  {
    trimp: tot.trimp,
    min: tot.min,
  };
  return trimp;
};



    /** aggiorna il grafico se esiste
    * @param {Object} params {
    *  'dettagli': [],
    *  'zone': [],
    *  'zone_attive': [],
    * }
    * @return {Object} gzone
    */
MSP.getZoneGrafico = function ({dettagli, zone, zone_attive}) {
    if (!zone) { return; }
    let totaliZone = {};

    dettagli && dettagli.forEach((d)=>{
        if (!d.ripetute) {
            d.ripetute = 1;
        }

        d.serie && d.serie.forEach((s)=>{
            let totale = MSP.sommaTotale(s.ripetute, s.andature, zone_attive, null) || false;
            if (totale) {
                var z = totale.zone;
                for(let iz in z){
                    if(!(iz in totaliZone)) {
                        totaliZone[iz] = {
                            mt: 0,
                            sec: 0,
                        };
                    }
                    totaliZone[iz].mt += +z[iz].mt * d.ripetute;
                    totaliZone[iz].sec += +z[iz].sec * d.ripetute;
                }
            }
        })
    });

    let backgroundColor = [];
    let tot = {mt:0, sec:0};
    for(let nomeZona in totaliZone){
        tot.mt += totaliZone[nomeZona].mt;
        tot.sec += totaliZone[nomeZona].sec;
    }
    let gzone = [];

    zone.forEach((el)=>{
        let percentage;
        let label = el.nome_custom; //da t_zone
        let value = {mt: 0, sec: 0};
        backgroundColor = el.colore; //da t_zone
        if (el.id in totaliZone) {
            percentage = {
                mt: Math.round((totaliZone[el.id].mt * 100 / tot.mt) * 100) / 100 || 0,
                sec: Math.round((totaliZone[el.id].sec * 100 / tot.sec) * 100) / 100 || 0
            };
            value = {
                mt: totaliZone[el.id].mt,
                sec: totaliZone[el.id].sec,
            }
        } else {
            percentage = {
                mt: 0,
                sec: 0,
            };
            value = {
                mt: 0,
                sec: 0,
            };
        }
        let zona = {
            id: el.id,
            label: label,
            percentage: percentage,
            backgroundColor: backgroundColor,
            value: value
        };
        gzone.push(zona);
    });
    return gzone;
}

MSP.filterTestsBySportId = function (tests, sport_id) {
    if (!(tests && tests.length)) { return []; }
    let filtered = tests.filter((el)=>(+el.sport_id === +sport_id));
    if (+_msp_constants.ID_NUOTO === +sport_id) {
        filtered = filtered.map(
            (test)=>{
                return {
                    ...test,
                    zone: test.zone.filter((el)=>(el.distanza=="100")),
                }
            });
    }
    filtered.sort((a,b)=>(a.um_tipo_posizione - b.um_tipo_posizione));
    return filtered;
}


MSP.formatDensita = function (densita) {
    if (!densita) {
        return "";
    }
    return Math.round(densita);
}

export { MSP as default };
